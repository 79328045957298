import React, { Fragment, useState, useEffect } from 'react';

import Layout from '../components/Layout'
import axios from 'axios';

export const BeerPageTemplate = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        'https://server.digitalpour.com/DashboardServer/api/v3/MenuItems/53c7f852fb890e032484b4d4/2/BottleDisplay?apiKey=53c7f792fb890e0e4ca04968',
      );
      console.log(result)
      setData(result.data);
    };
    fetchData();
  }, []);
  const pickColor = (quantity) => {
    if (quantity < .15) {
      return "maroon"
    } else if (quantity < .35) {
      return "DarkOrange"
    } else if (quantity < .5) {
      return "Orange"
    } else {
      return "DarkGreen"
    }
  }
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                Cans and Bottles
              </h2>
              {data && data.map((beer) => {
                // In come cases objects before breweryUrl may not exist (Brewery / Beverage doesn't exist).
                // To solve this breweryUrl will be null if any parent object doesn't exist.
                const breweryUrl = beer.MenuItemProductDetail && beer.MenuItemProductDetail.Beverage && beer.MenuItemProductDetail.Beverage.Brewery && beer.MenuItemProductDetail.Beverage.Brewery.BreweryUrl
                return (
                  <div style={styles.beerMenu}>
                    <a href={breweryUrl ? "https://" + breweryUrl : "https://highcraftapex.com"}>
                     <img src={beer.MenuItemProductDetail.Beverage.ResolvedLogoImageUrl} style={{height: "60px"}} alt="logo" />
                    </a>
                    <div style={styles.beerInfo}>
                      <div style={styles.beerTitle}>{beer.MenuItemProductDetail.FullBeverageName}</div>
                      <p>
                        <a href={breweryUrl ? "https://" + breweryUrl : "https://highcraftapex.com"}><strong style={{color: "black"}}>{beer.MenuItemProductDetail.FullProducerList}</strong></a> - <i>{beer.MenuItemProductDetail.FullStyleName} ({beer.MenuItemProductDetail.Beverage.Abv}%)</i>
                      </p>
                      <div style={styles.priceMenu}>
                        <div style={styles.wrapper}>
                          <div style={styles.progressBar}>
                            <span style={{
                              display: "block",
                              height: "22px",
                              backgroundColor: pickColor(beer.MenuItemProductDetail.PercentFull),
                              borderRadius: "3px",
                              transition: "width 500ms ease-in-out",
                              width: `${beer.MenuItemProductDetail.PercentFull * 100}%`
                            }}>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const styles = {
  beerMenu: {
    display: "flex",
    marginTop: "1em",
    marginBottom: "1em"
  },
  beerInfo: {
    rowDirection: "column",
    marginLeft: "1em",
    marginRight: "1em",
    width: "100%"
  },
  beerTitle: {
    fontSize: "20px",
    fontWeight: "bold"
  },
  priceMenu: {
    display: "flex",
    justifyContent: "space-evenly",
    rowDirection: "row",
  },
  wrapper: {
    marginTop: "1em",
    width: "500px"
  },
  progressBar: {
    width: "100%",
    backgroundColor: "#e0e0e0",
    padding: "3px",
    borderRadius: "3px",
  },
}

const BeerPage = () => {
  return (
      <BeerPageTemplate
      />
  )
}

export default BeerPage
